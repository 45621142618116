import { createGid } from '/utils/graph-ql';

const GIFT_SUBSCRIPTION_LINK = '/pages/boxer-subscription-gift';

const MENS_SUBSCRIPTION_IMAGE = 'https://cdn.shinesty.com/2022-10-06/mens-sub-t2-alt.jpg';

const MENS_SUBSCRIPTION_LINK = '/products/monthly-boxer-subscription';

const WOMENS_SUBSCRIPTION_LINK = '/products/monthly-thong-subscription';

const COUPLES_SUBSCRIPTION_STANDARD_CARD = {
	link: '/products/couples-underwear-subscription',
};

const MENS_SUBSCRIPTION_STANDARD_CARD = {
	imageSrc: MENS_SUBSCRIPTION_IMAGE,
	link: MENS_SUBSCRIPTION_LINK,
};

const MENS_SUBSCRIPTION_STANDARD_GIFT_CARD = {
	imageSrc: MENS_SUBSCRIPTION_IMAGE,
	link: GIFT_SUBSCRIPTION_LINK,
};

const WOMENS_SUBSCRIPTION_STANDARD_CARD = {
	link: WOMENS_SUBSCRIPTION_LINK,
};

const WOMENS_SUBSCRIPTION_SUPER_CARD = {
	link: WOMENS_SUBSCRIPTION_LINK,
	alternateTitle: 'Monthly Underwear Subscription',
};

const PRODUCT_ID_LOOKUP = {
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMDMxNzM=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MDY1NjU=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4MDgyNjE=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMDQ4Njk=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5MzkzMzM=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI5NzIxMDE=': {
		default: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NDEwMjk=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI3NzU0OTM=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTI4NzM3OTc=': {
		default: MENS_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxMzU5NDE=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwMzc2Mzc=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMwNzA0MDU=': {
		default: MENS_SUBSCRIPTION_STANDARD_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMzQyNDU=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMxNjg3MDk=': {
		default: WOMENS_SUBSCRIPTION_STANDARD_CARD,
		supercollection: WOMENS_SUBSCRIPTION_SUPER_CARD,
	},
	'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3ODU2MTMyMDE0Nzc=': {
		default: MENS_SUBSCRIPTION_STANDARD_CARD,
	},
};

const SUBSCRIPTION_LOOKUP = {
	// product types (ordergroove)
	boxers: MENS_SUBSCRIPTION_STANDARD_CARD,
	'boxers - fly': MENS_SUBSCRIPTION_STANDARD_CARD,
	'boxers - long - fly': MENS_SUBSCRIPTION_STANDARD_CARD,
	'boxers - trunk': MENS_SUBSCRIPTION_STANDARD_CARD,

	// conjured products (to remove)
	bikini: WOMENS_SUBSCRIPTION_STANDARD_CARD,
	boy: WOMENS_SUBSCRIPTION_STANDARD_CARD,
	brief: MENS_SUBSCRIPTION_STANDARD_CARD,
	cheeky: WOMENS_SUBSCRIPTION_STANDARD_CARD,
	couples: COUPLES_SUBSCRIPTION_STANDARD_CARD,
	fly: MENS_SUBSCRIPTION_STANDARD_CARD,
	giftlong: {
		imageSrc:
			'https://cdn.shopify.com/s/files/1/0234/5963/products/LongBoxer-CP_95x.gif?v=1624987842',
		link: GIFT_SUBSCRIPTION_LINK,
	},
	giftstandard: MENS_SUBSCRIPTION_STANDARD_CARD,
	long: MENS_SUBSCRIPTION_STANDARD_CARD,
	standard: MENS_SUBSCRIPTION_STANDARD_CARD,
	thong: WOMENS_SUBSCRIPTION_STANDARD_CARD,
	trunk: MENS_SUBSCRIPTION_STANDARD_CARD,

	// parent products
	'boxer-subscription-gift': MENS_SUBSCRIPTION_STANDARD_GIFT_CARD,
	'boxer subscription gift': {
		imageSrc:
			'https://cdn.shopify.com/s/files/1/0234/5963/products/GiftSubs_Leader.png?v=1647636424',
		link: GIFT_SUBSCRIPTION_LINK,
	},
	'classic-boxer-subscription': MENS_SUBSCRIPTION_STANDARD_CARD,
	'classic boxer subscription': MENS_SUBSCRIPTION_STANDARD_CARD,
	'monthly boxer subscription': MENS_SUBSCRIPTION_STANDARD_CARD,
	'monthly bikini underwear subscription': WOMENS_SUBSCRIPTION_STANDARD_CARD,
	'monthly boyshort underwear subscription': WOMENS_SUBSCRIPTION_STANDARD_CARD,
	'monthly cheeky subscription': WOMENS_SUBSCRIPTION_STANDARD_CARD,
	'monthly matching boxer thong subscription': COUPLES_SUBSCRIPTION_STANDARD_CARD,
	'surprise boxer subscription': MENS_SUBSCRIPTION_STANDARD_CARD,
	'wild boxer subscription': MENS_SUBSCRIPTION_STANDARD_CARD,
};

const getProductCardDetails = (subscriptionType, productId, location) => {
	if (
		(!subscriptionType || typeof subscriptionType !== 'string') &&
		(!productId || typeof productId !== 'number')
	) {
		return {};
	}

	let gid;
	if (productId) {
		gid = createGid('Product', productId);
	}

	if (gid && PRODUCT_ID_LOOKUP[gid]) {
		return location && PRODUCT_ID_LOOKUP[gid][location]
			? PRODUCT_ID_LOOKUP[gid][location]
			: PRODUCT_ID_LOOKUP[gid]['default'];
	}

	return SUBSCRIPTION_LOOKUP[subscriptionType.toLowerCase()] || {};
};

module.exports = {
	getProductCardDetails,
};
