import CountryFunctionality from '../CountryFunctionality';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import Link from 'next/link';
import Price from '../Price';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getProductCardDetails } from '/services/static/product-card';
import { logTryCatch } from '/utils/logging';
import { observer } from 'mobx-react-lite';
import { useCartStore } from '/state/cart-context';

import {
	ALWAYS_ON_FREE_GIFT,
	BUILD_A_PACK,
	CAN_CHANGE_QUANTITY,
	DEFAULT_IMAGE,
	DISCOUNTED_LINE_PRICE,
	FIRST_PAIR_IMAGE,
	FIRST_PAIR_IMAGES,
	FIRST_PAIR_TITLE,
	FROM_PRODUCT_HANDLE,
	FROM_WOMENS_IMAGE,
	HAS_SUB_ITEMS,
	HIDE_QUANTITY,
	IS_CHRISTMAS,
	IS_HIDDEN,
	IS_OPTED_OUT,
	IS_SUBSCRIPTION,
	IS_SUB_TOGGLE,
	LINE_PRICE,
	LINK_TO,
	MINI_CART_DATA,
	MYSTERY_IMAGE,
	MYSTERY_PACK_ITEM,
	ORIGINAL_PRICE,
	PACK_ID,
	PRODUCT_TYPE,
	PROMOTIONAL_ITEM,
	PROMOTIONAL_ITEM_INTERACTION,
	SHOW_DISCOUNT,
	SITEWIDE_PACK_BUILDER,
	SUBSCRIPTION_FREQUENCY,
	SUBSCRIPTION_SUBTITLE,
	SUBSCRIPTION_TITLE,
	SUBSCRIPTION_TYPE,
} from '/services/static/attribute-keys';

function ExtraPair({ image, productType, size }) {
	return (
		<div className="border-4 border-black flex items-center justify-center m-2 p-1 rounded-md">
			{image && <Image src={image} alt={productType} height={70} width={70} objectFit="contain" />}
			<Typography component="p" variant="body">
				{productType} - {size}
			</Typography>
		</div>
	);
}

const CartItem = ({ convertToSubscription, item, removeCallback, quantityCallback }) => {
	const cart = useCartStore();
	const updateOptedOut = async (line, attributeVal) => {
		if (attributeVal === 'true') {
			line.attributes[IS_OPTED_OUT] = 'false';
		} else {
			line.attributes[IS_OPTED_OUT] = 'true';
		}
		const output = Object.entries(line.attributes).map(([key, value]) => ({
			key,
			value,
		}));

		const updateLine = [
			{
				id: line.id,
				quantity: 1,
				attributes: output,
			},
		];
		await cart.updateItems(updateLine, true);
	};
	const isShippingProtection = item?.merchandise?.product?.title?.includes('Shipping');

	if (item.attributes[IS_HIDDEN] || isShippingProtection) {
		return null;
	}

	const cartLine = item;
	const attributes = cartLine.attributes || {};
	const quantity = cartLine.quantity;
	const merchandise = cartLine.merchandise;
	const product = merchandise.product;
	const isPromotionalItem = attributes[PROMOTIONAL_ITEM] || item.linePrice == 0;
	const isPromotionalInteraction = item.attributes[PROMOTIONAL_ITEM_INTERACTION];

	const splitTitle = product?.title?.split(' | ');

	if (attributes[SITEWIDE_PACK_BUILDER]) {
		return null;
	}
	const discountedLinePrice =
		attributes[DISCOUNTED_LINE_PRICE] && parseFloat(attributes[DISCOUNTED_LINE_PRICE]) * quantity;

	const cardData = {
		alwaysOnFreeGift: attributes[ALWAYS_ON_FREE_GIFT] === 'true',
		canChangeQuantity: attributes[CAN_CHANGE_QUANTITY] !== 'false',
		compareAtPrice: parseFloat(merchandise?.compareAtPriceV2?.amount),
		extras: [],
		hideQuantity: attributes[HIDE_QUANTITY] === 'true',
		image: merchandise?.image?.url || cartLine.attributes[DEFAULT_IMAGE],
		isOptedOut: attributes[IS_OPTED_OUT] || 'false',
		linePrice: discountedLinePrice || attributes[LINE_PRICE] || item.linePrice,
		link: attributes[LINK_TO] || attributes[FROM_PRODUCT_HANDLE] || `/products/${product.handle}`,
		originalPrice: attributes[ORIGINAL_PRICE] || item.originalPrice,
		productType: attributes[PRODUCT_TYPE],
		showDiscount: attributes[SHOW_DISCOUNT] || item.showDiscount,
		showSubscriptionTag: false,
		showSubtitles: true,
		subTitle: item?.merchandise?.product?.tags?.includes('notSizeVariant')
			? merchandise.title
			: `Size ${merchandise.title}`,
		title: splitTitle.length > 1 ? splitTitle[0] : product.title,
		showSubscriptionToggle:
			!item?.merchandise?.product?.productType?.includes('Sock') &&
			!item?.attributes[SITEWIDE_PACK_BUILDER] &&
			!isPromotionalItem &&
			!attributes[PACK_ID] &&
			quantity == 1 &&
			!item?.merchandise?.product?.productType?.includes('Cooling') &&
			(item?.merchandise?.product?.tags?.includes('show subscribe option') ||
				item?.merchandise?.product?.tags?.includes('Show Subscribe Option')) &&
			(!attributes[IS_SUBSCRIPTION] || attributes[IS_SUB_TOGGLE]),
	};

	if (attributes[MYSTERY_PACK_ITEM] === 'true') {
		cardData.badge = attributes[IS_CHRISTMAS] ? '🎄 Christmas Mystery' : 'Pack Builder';
		cardData.image = attributes[MYSTERY_IMAGE];
		cardData.link = '/products/mystery-underwear-pack-builder';
		cardData.title = `Mystery ${product.productType}`;
		cardData.canChangeQuantity = false;
		cardData.hideQuantity = false;
	}
	if (attributes[SITEWIDE_PACK_BUILDER]) {
		cardData.isSitewidePack = true;
	}
	if (cardData.alwaysOnFreeGift) {
		return null;
	}
	/********************************/
	/* preorder items ***************/
	/********************************/
	if (splitTitle.length > 2 && splitTitle[2].toLowerCase() === 'pre-order') {
		cardData.badge = 'Preorder';
	}

	/********************************/
	/* monthly subscription *********/
	/********************************/
	if (attributes && attributes[IS_SUBSCRIPTION] === 'true') {
		const productCardDetails = getProductCardDetails(attributes[PRODUCT_TYPE]);

		if (productCardDetails.link) {
			cardData.link = productCardDetails.link;
		}

		cardData.badge = 'New Subscription';

		const isCouplesSubscription = attributes[SUBSCRIPTION_TYPE] === 'couples';
		const isQuarterlySubscription = attributes[SUBSCRIPTION_FREQUENCY] === 'quarterly';

		if (attributes[FIRST_PAIR_TITLE]) {
			cardData.title = (
				<>
					<Typography variant="body">First Pair: </Typography>
					<Typography variant="subtitle-sm">{attributes[FIRST_PAIR_TITLE]}</Typography>
				</>
			);
		}

		if (isQuarterlySubscription) {
			cardData.title = (
				<>
					<Typography variant="subtitle-sm">{attributes[SUBSCRIPTION_TITLE]}</Typography>
				</>
			);
			cardData.showSubtitles = false;
		}

		if (!isQuarterlySubscription && attributes[SUBSCRIPTION_TITLE]) {
			cardData.subTitle = attributes[SUBSCRIPTION_TITLE];
		}

		if (attributes[SUBSCRIPTION_SUBTITLE] && !isCouplesSubscription) {
			cardData.subTitleTwo = attributes[SUBSCRIPTION_SUBTITLE];
		}

		if (isCouplesSubscription) {
			cardData.badge = 'Matching Subscription';
		}

		if (attributes[SUBSCRIPTION_TYPE] === 'single') {
			cardData.badge = 'New Subscription';
		}

		if (attributes[FIRST_PAIR_IMAGE]) {
			cardData.image = attributes[FIRST_PAIR_IMAGE];
		} else if (attributes[DEFAULT_IMAGE]) {
			cardData.image = attributes[DEFAULT_IMAGE];
		}

		if (attributes[FIRST_PAIR_IMAGES]) {
			try {
				const images = JSON.parse(attributes[FIRST_PAIR_IMAGES]);
				delete cardData.image;
				cardData.images = images;
			} catch (e) {
				logTryCatch(e);
			}
		}

		if (attributes[MINI_CART_DATA]) {
			try {
				const parsed = JSON.parse(attributes[MINI_CART_DATA]);
				parsed.forEach((product, i) => {
					cardData.extras.push(
						<ExtraPair
							image={product.image}
							key={i}
							productType={product.productType}
							size={product.size}
						/>,
					);
				});
			} catch (e) {
				logTryCatch('mini cart data present but could not be parsed.');
			}
		}
	}

	/********************************/
	/* build a pack *****************/
	/********************************/
	if (attributes && attributes[BUILD_A_PACK]) {
		cardData.badge = 'Pack Builder';
	}

	/********************************/
	/* build a pack with box ********/
	/********************************/
	if (attributes && attributes[HAS_SUB_ITEMS]) {
		cardData.subTitle = Object.keys(attributes)
			.filter((k) => k.indexOf('subItem') != -1)
			.map((k) => attributes[k])
			.join(', ');
	}

	/********************************/
	/* uses alternate image *********/
	/********************************/
	if (attributes && attributes[FROM_WOMENS_IMAGE]) {
		cardData.image = attributes[FROM_WOMENS_IMAGE];
	}

	if (isPromotionalItem === 'true') {
		cardData.hideQuantity = true;
	}
	let cardClass = 'grid grid-cols-4 mb-2 ';
	if (cardData.isOptedOut === 'true') {
		cardClass += 'opacity-60';
	}
	if (isPromotionalItem) {
		cardClass += 'shadow-lg rounded-lg mx-5 border border-gray-100 mt-2';
	}
	if (cardData.isSitewidePack) {
		cardClass += 'border border-2 border-[#2100f5] rounded-lg mx-2 mt-2 w-[50%]';
	}
	const showComparePrice =
		cardData.compareAtPrice * quantity > cardData.linePrice && !cardData.showDiscount;
	return (
		<div className="relative">
			<div className={classNames(cardClass)}>
				<div className="flex items-center align-middle justify-center">
					<div className="flex text-center">
						{isPromotionalItem ? (
							<div className="h-[80px] ml-1 my-2 relative w-[80px]">
								<Image
									src={cardData.image}
									alt={product.title}
									height={100}
									width={100}
									layout="fill"
									objectFit="contain"
									objectPosition="top left"
								/>
							</div>
						) : (
							<Link href={cardData.link} passHref>
								<a className="flex flex-grow">
									{cardData.image && (
										<Image
											src={cardData.image}
											alt={product.title}
											height={120}
											width={120}
											objectFit="contain"
											objectPosition="top"
										/>
									)}
									{cardData.images && (
										<div className="relative h-full w-full">
											{cardData.images.map((image, i) => {
												return (
													<div
														className={classNames(
															'absolute',
															i === 0 ? 'top-0 left-0' : '',
															i === 1 ? 'bottom-0 right-0' : '',
														)}
														key={i}>
														<Image
															alt={product.title}
															height={70}
															objectFit="contain"
															src={image}
															width={70}
														/>
													</div>
												);
											})}
										</div>
									)}
									{/* {!cardData.image && !cardData.images && <Typography>No image</Typography>} */}
								</a>
							</Link>
						)}
					</div>
				</div>
				<div className="flex flex-col px-2 col-span-3">
					{!cardData.isSitewidePack && (
						<div className="flex">
							{isPromotionalItem ? (
								<div className="flex">
									<div>
										<div className="mt-2">{cardData.title}</div>
										{cardData.showSubtitles && cardData.subTitle && (
											<div>{cardData.subTitle.replace('Size OSFA', '')}</div>
										)}
										{cardData.showSubtitles && cardData.subTitleTwo && (
											<div>{cardData.subTitleTwo}</div>
										)}
										{isPromotionalInteraction && cardData.isOptedOut === 'false' && (
											<div>
												<button
													className="text-secondary underline"
													title="change gift item size"
													onClick={removeCallback}>
													Change Size or Item
												</button>
											</div>
										)}
									</div>
								</div>
							) : (
								<>
									<div>
										{cardData.badge && (
											<div className="flex mt-1 mb-1">
												<Typography variant="body" className="text-primary font-bold">
													{cardData.badge}
												</Typography>
											</div>
										)}

										<Link href={cardData.link} passHref>
											<a>
												<div className="mt-2">{cardData.title}</div>
												{cardData.showSubtitles && cardData.subTitle && (
													<div>{cardData.subTitle}</div>
												)}
												{cardData.showSubtitles && cardData.subTitleTwo && (
													<div>{cardData.subTitleTwo}</div>
												)}
											</a>
										</Link>
									</div>
								</>
							)}

							{!isPromotionalItem && (
								<div className="m-2 ml-auto min-w-[20px]">
									<button title="remove item from cart" onClick={removeCallback}>
										<Icon name="remove" height="20px" width="20px" />
									</button>
								</div>
							)}
						</div>
					)}
					<div className="flex">
						{!cardData.hideQuantity || (quantity > 1 && <div className="my-2">{quantity}</div>)}
						{cardData.canChangeQuantity && (
							<div className="my-2 flex">
								<button
									className="px-4 py-2 text-gray-600 bg-gray-200 text-center align-middle rounded-full"
									title="decrease quantity"
									onClick={() => quantityCallback((cartLine.splitItemsQuantity || quantity) - 1)}>
									-
								</button>
								<span className="px-4 self-center">{quantity}</span>
								<button
									className="px-4 py-2 text-gray-600 bg-gray-200 text-center align-middle rounded-full disabled:opacity-30"
									title="increase quantity"
									onClick={() => quantityCallback((cartLine.splitItemsQuantity || quantity) + 1)}>
									+
								</button>
							</div>
						)}
						<div className="my-2 ml-auto self-center flex justify-between w-auto">
							{cardData.showDiscount && (
								<span aria-hidden="true" className="pr-4 line-through italic text-gray-500">
									<Price price={cardData.originalPrice} />
								</span>
							)}
							{isPromotionalItem ? (
								<div className="">
									<div>
										{!cardData.showDiscount && (
											<span aria-hidden="true" className="mr-2 text-gray-300 line-through pt-1">
												<Price price={cardData.compareAtPrice * quantity} />
											</span>
										)}

										<span className="font-semibold my-[2px] tracking-wider">Free</span>
									</div>

									{false && (
										<button
											onClick={() =>
												updateOptedOut(item, cardData.isOptedOut == 'true' ? 'true' : 'false')
											}
											className="text-blue-600 font-semibold py-1 px-2">
											{cardData.isOptedOut == 'true' ? 'Opt In' : 'Opt Out'}
										</button>
									)}
								</div>
							) : (
								<>
									{showComparePrice && (
										<span aria-hidden="true" className="mr-2 text-gray-300 line-through">
											<Price price={cardData.compareAtPrice * quantity} />
										</span>
									)}
									<span className="mr-2">
										<Price price={cardData.linePrice} />
									</span>
								</>
							)}
						</div>
					</div>
					<CountryFunctionality functionalityKey="subscription">
						{cardData.showSubscriptionToggle ? (
							<div className="flex">
								<label htmlFor="toggle-status" className="text-gray-900 ml-auto mr-2">
									{' '}
									Subscribe &amp; Save 35%
								</label>
								<Toggle
									id="toggle-status"
									defaultChecked={attributes[IS_SUBSCRIPTION]}
									onChange={convertToSubscription}
								/>
							</div>
						) : (
							<span></span>
						)}
					</CountryFunctionality>
					{cardData.extraData && (
						<div className="flex">
							<Typography component="p" variant="body">
								{cardData.extraData}
							</Typography>
						</div>
					)}
				</div>
			</div>
			{isPromotionalItem && cardData.isOptedOut === 'false' && (
				<div className="absolute top-0 left-6">
					<Image
						alt="icon"
						height={30}
						src="https://cdn.shinesty.com/2022-11-16/gift-bubble-purple.svg"
						width={30}
					/>
				</div>
			)}
			{cardData.extras && (
				<div className="grid grid-cols-2 px-4">
					{cardData.extras.map((extra) => {
						return extra;
					})}
				</div>
			)}
		</div>
	);
};

ExtraPair.propTypes = {
	image: PropTypes.string.isRequired,
	productType: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
};

CartItem.propTypes = {
	convertToSubscription: PropTypes.func,
	item: PropTypes.object.isRequired,
	quantityCallback: PropTypes.func.isRequired,
	removeCallback: PropTypes.func.isRequired,
};

export default observer(CartItem);
